import {useEffect, useMemo, useState, useRef} from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Link,
  Text,
  View,
  VStack,
  useTheme,
  HStack,
  Center,
  Spinner,
} from 'native-base';
import OtpInput from 'react-otp-input';
import {MdErrorOutline} from 'react-icons/md';
import readXlsxFile from 'read-excel-file';
import {MaterialReactTable, useMaterialReactTable} from 'material-react-table';
import {FaCircleInfo} from 'react-icons/fa6';
import {useAuth} from '../auth.js';
import {getAuth, RecaptchaVerifier} from 'firebase/auth';
import s from '../styles/background.module.css';

export default function Onboarding() {
  const {colors} = useTheme();
  const [step, setStep] = useState(2);
  const [invalidData, setInvalidData] = useState([]);
  const phoneInputRef = useRef(null);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [hasSentOTP, setHasSentOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const [isVerifiedOtp, setIsVerifiedOtp] = useState(false);
  const [isVerifyngOtp, setIsVerifyingOtp] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const drop = useRef(null);
  const auth = useAuth();
  const firebaseAuth = getAuth();
  const [isDraggedOver, setIsDraggedOver] = useState(false);
  const [file, setFile] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [fileError, setFileError] = useState();
  const fileInput = useRef(null);
  const [departments, setDepartments] = useState([
    'Sales',
    'Service',
    'Parts',
    'Others',
  ]);

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtpAndContinue();
    }
  }, [otp]);

  const sendOtp = async () => {
    setIsSendingOTP(true);

    try {
      const appVerifier = new RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
        firebaseAuth,
      );

      await auth.signUp('+91' + phoneNumber, appVerifier);
      setHasSentOTP(true);
    } catch (error) {
      console.error(error);
    }
    setIsSendingOTP(false);
  };

  const verifyOtpAndContinue = async () => {
    setIsVerifyingOtp(true);
    try {
      await auth.verifyOtp(otp);
      setIsVerifiedOtp(true);
    } catch (error) {
      setInvalidData(['otp']);
    }
    setIsVerifyingOtp(false);
  };

  const handleDragEnter = e => {
    setIsDraggedOver(true);
    e.preventDefault();
  };

  const handleDragLeave = e => {
    setIsDraggedOver(false);
  };

  const handleDrop = e => {
    e.preventDefault();
    if (e.dataTransfer.files.length) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  const handleFileChange = e => {
    handleFile(e.target.files[0]);
  };

  const handleFile = f => {
    setIsDraggedOver(false);
    if (!f.type.includes('sheet')) {
      setFileError(
        'Invalid file format. Please make sure the that file is an .xlsx file.',
      );
      return;
    }
    if (f) {
      setFileError();
      setFile(f);
      readFile(f);
    }
  };

  useEffect(() => {
    if (!drop || !drop.current) {
      return;
    }
    drop.current.addEventListener('dragover', handleDragEnter);
    drop.current.addEventListener('dragleave', handleDragLeave);
    drop.current.addEventListener('drop', handleDrop);
    fileInput.current.addEventListener('change', handleFileChange);

    return () => {
      if (!drop || !drop.current) {
        return;
      }
      drop.current.removeEventListener('dragover', handleDragEnter);
      drop.current.removeEventListener('dragleave', handleDragLeave);
      drop.current.removeEventListener('drop', handleDrop);
      fileInput.current.removeEventListener('change', handleFileChange);
    };
  }, [file, step]);

  const isPhoneNumberValid = number => {
    const regexp = /^(\s|\S)(\d{0,9})$/;
    return regexp.test(number);
  };

  async function readFile(f) {
    try {
      const data = await readXlsxFile(f, {
        schema: {
          Name: {
            prop: 'Name',
            type: String,
            required: true,
          },
          'Phone number': {
            prop: 'Phone number',
            type: String,
            required: true,
          },
          Department: {
            prop: 'Department',
            type: String,
            required: true,
          },
          Level: {
            prop: 'Level',
            type: String,
            required: true,
          },
        },
      });

      if (
        data.rows.some(row => {
          return !['Name', 'Phone number', 'Department', 'Level'].every(prop =>
            Object.keys(row).includes(prop),
          );
        })
      ) {
        setFile(null);
        setFileError(
          'Sheet not valid. Please make sure that the file has all the mentioned columns for all the rows.',
        );
        return;
      }
      setEmployees(data.rows);
    } catch (error) {
      setFile(null);
      setFileError(
        'Invalid file format. Please make sure that the file has all the mentioned columns.',
      );
    }
  }

  const columns = useMemo(() => {
    return [
      {
        header: 'Name',
        accessorKey: 'Name',
      },
      {
        header: 'Phone number',
        accessorKey: 'Phone number',
      },
      {
        header: 'Department',
        accessorKey: 'Department',
      },
      {
        header: 'Level',
        accessorKey: 'Level',
      },
    ];
  }, []);

  const table = useMaterialReactTable({
    columns,
    data: employees,
    enableSorting: false,
    enableRowNumbers: true,
    enableHiding: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableColumnOrdering: false,
    enableColumnFilter: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableTopToolbar: false,
    enableStickyHeader: true,
    positionActionsColumn: 'first',
    positionGlobalFilter: 'left',
    initialState: {
      density: 'compact',
    },
    muiTablePaperProps: {
      elevation: 0,
      sx: {
        borderRadius: '8px',
        border: '1px solid #E0E0E0',
        overflow: 'auto',
        height: '100%',
        flex: '1',
      },
    },
    muiTableContainerProps: {sx: {maxHeight: '400px'}},
    muiTableBodyProps: {
      sx: {
        height: '40vh',
      },
    },
    enableFullScreenToggle: false,
    enableDensityToggle: false,
  });

  return (
    <View
      w="full"
      alignItems="center"
      bg="black"
      h="100vh"
      justifyContent="center">
      <VStack
        w={['full', step === 4 ? '1000px' : '600px']}
        maxW={step === 4 ? '1000px' : '600px'}
        space="8"
        bg="white"
        minH={['100vh', '600px']}
        py="12"
        borderRadius={['none', 'lg']}
        overflow="hidden"
        alignItems="center">
        {isVerifyngOtp && (
          <Center
            position="absolute"
            h="full"
            w="full"
            bg="rgba(255, 255, 255, 0.5)"
            zIndex="2"
            mt="-12">
            <Spinner size="lg" color="indigo.500" />
          </Center>
        )}
        {step === 1 && (
          <VStack px={['4', '16']} w="full" flex="1" justifyContent="center">
            <VStack space="16">
              <Heading textAlign={['center', 'left']}>
                Welcome!
                <br />
                Let's get started
              </Heading>
              {!hasSentOTP ? (
                <VStack space="8">
                  <FormControl isInvalid={invalidData.includes('phoneNumber')}>
                    <InputGroup w="full">
                      <InputLeftAddon children={'+91'} />
                      <Input
                        size="lg"
                        flex="1"
                        placeholder="Enter your mobile number"
                        ref={phoneInputRef}
                        onChangeText={val => {
                          setInvalidData([]);
                          setPhoneNumber(val);
                        }}
                        isInvalid={invalidData.includes('phoneNumber')}
                      />
                    </InputGroup>

                    <FormControl.ErrorMessage
                      leftIcon={
                        <MdErrorOutline size="12" color={colors.error['500']} />
                      }>
                      Invalid phone number
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <div id="recaptcha-container"></div>
                  <Button
                    colorScheme="indigo"
                    isLoading={isSendingOTP}
                    isLoadingText="Sending OTP"
                    onPress={() => {
                      if (!isPhoneNumberValid(phoneInputRef.current.value)) {
                        setInvalidData(val => [...val, 'phoneNumber']);
                        phoneInputRef.current.focus();
                        return;
                      }
                      sendOtp();
                    }}>
                    Send OTP & Create Account
                  </Button>
                </VStack>
              ) : !isVerifiedOtp ? (
                <VStack space="4" w="full" alignItems={['center', 'left']}>
                  <Text textAlign={['center', 'left']}>
                    OTP sent to <Text bold>+91 {phoneNumber}</Text>.{' '}
                    <Link
                      isUnderlined={false}
                      color="indigo.600"
                      onPress={() => {
                        setHasSentOTP(false);
                        setOtp('');
                      }}>
                      Change number?
                    </Link>
                    <br />
                    Please enter the OTP to continue.
                  </Text>
                  <OtpInput
                    value={otp}
                    onChange={val => {
                      setInvalidData([]);
                      setOtp(val);
                    }}
                    containerStyle={{
                      width: '100%',
                      justifyContent: ['center', 'left'],
                    }}
                    inputStyle={{
                      width: '48px',
                      height: '48px',
                      fontSize: '24px',
                      borderRadius: '4px',
                      border: `1px solid ${colors.muted['300']}`,
                      outlineColor: colors.indigo['300'],
                    }}
                    renderSeparator={<Box width="8px"> </Box>}
                    numInputs="6"
                    shouldAutoFocus
                    renderInput={props => <input {...props} />}
                  />
                  <FormControl isInvalid={invalidData.includes('otp')}>
                    <FormControl.ErrorMessage
                      leftIcon={
                        <MdErrorOutline size="12" color={colors.error['500']} />
                      }>
                      Invalid OTP
                    </FormControl.ErrorMessage>
                  </FormControl>
                  <Text color="muted.600">
                    Didn't receive the OTP?{' '}
                    <Link isUnderlined={false} color="indigo.600">
                      Resend
                    </Link>
                  </Text>
                </VStack>
              ) : (
                <Button
                  size="lg"
                  colorScheme="indigo"
                  onPress={() => setStep(2)}>
                  Continue to set up Organization
                </Button>
              )}
            </VStack>

            <Box alignItems="center" p="4" position="absolute" bottom="4">
              <Text color="muted.400" textAlign="center">
                By continuing you agree to our{' '}
                <Link color="indigo.400" href="/privacy">
                  privacy policy
                </Link>{' '}
                and{' '}
                <Link color="indigo.400" href="/legal">
                  terms of service
                </Link>
                .
              </Text>
            </Box>
          </VStack>
        )}

        {step === 2 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>
              Organization Details
            </Heading>

            <VStack space="4">
              <FormControl isInvalid={invalidData.includes('orgName')}>
                <VStack space="1">
                  <FormControl.Label>Organization name</FormControl.Label>
                  <Input
                    placeholder="Organization name"
                    onChangeText={val => {
                      setInvalidData([]);
                      setOrganizationName(val);
                    }}
                    isInvalid={invalidData.includes('orgName')}
                    colorScheme="indigo"
                    autoFocus
                  />
                  <FormControl.ErrorMessage
                    leftIcon={
                      <MdErrorOutline size="12" color={colors.error['500']} />
                    }>
                    Invalid organization name
                  </FormControl.ErrorMessage>
                </VStack>
              </FormControl>
              <FormControl isInvalid={invalidData.includes('departments')}>
                <VStack space="1">
                  <FormControl.Label>
                    Departments (Select atleast one)
                  </FormControl.Label>
                  <Checkbox.Group
                    accessibilityLabel="Select departments"
                    defaultValue={departments}
                    onChange={values => {
                      setDepartments(values);
                      
                    }}>
                    <Checkbox value="Sales" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Sales</Text>
                    </Checkbox>
                    <Checkbox value="Service" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Service</Text>
                    </Checkbox>
                    <Checkbox value="Parts" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Parts</Text>
                    </Checkbox>
                    <Checkbox value="Others" colorScheme="indigo" boxSize="4">
                      <Text color="muted.500">Others</Text>
                    </Checkbox>
                  </Checkbox.Group>
                  <FormControl.ErrorMessage
                    leftIcon={
                      <MdErrorOutline size="12" color={colors.error['500']} />
                    }>
                    {departments.length === 1 && departments[0] === 'Others'
                      ? "Select atleast one departments other than 'Others'"
                      : 'Select atleast one department'}
                  </FormControl.ErrorMessage>
                </VStack>
              </FormControl>
            </VStack>

            <Button
              colorScheme="indigo"
              onPress={() => {
                if (!organizationName || organizationName.trim().length < 3) {
                  setInvalidData(val => [...val, 'orgName']);
                  return;
                }
                if (
                  departments.length === 0 ||
                  (departments.length === 1 && departments[0] === 'Others')
                ) {
                  setInvalidData(val => [...val, 'departments']);
                  return;
                }
                setInvalidData([]);
                setStep(3);
              }}>
              Continue
            </Button>
          </VStack>
        )}

        {step === 3 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Accounts access</Heading>
            <FormControl>
              <VStack space="2">
                <VStack
                  w="full"
                  space="4"
                  p="4"
                  borderWidth="1"
                  borderColor="muted.200"
                  borderRadius="lg">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Username</FormControl.Label>
                    <Input value="FTP123" flex="1" />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Password</FormControl.Label>
                    <Input value="Aezakmi" flex="1" />
                  </HStack>
                </VStack>

                <VStack
                  w="full"
                  space="4"
                  p="4"
                  borderWidth="1"
                  borderColor="muted.200"
                  borderRadius="lg">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Username</FormControl.Label>
                    <Input value="FTP123" flex="1" />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Password</FormControl.Label>
                    <Input value="Aezakmi" flex="1" />
                  </HStack>
                </VStack>

                <VStack
                  w="full"
                  space="4"
                  p="4"
                  borderWidth="1"
                  borderColor="muted.200"
                  borderRadius="lg">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Username</FormControl.Label>
                    <Input value="FTP123" flex="1" />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>Password</FormControl.Label>
                    <Input value="Aezakmi" flex="1" />
                  </HStack>
                </VStack>
              </VStack>
            </FormControl>

            <Button
              colorScheme="indigo"
              onPress={() => {
                // setAreDetailsInvalid(!areDetailsInvalid);
                // phoneInputRef.current.focus();
                setStep(4);
              }}>
              Continue
            </Button>
          </VStack>
        )}

        {step === 4 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Add your employees</Heading>
            <VStack space="4">
              <Text>
                Create an excel sheet of your employees with the following
                columns only:
                <br />
                <Text bold>Name, Phone, Department, Level</Text>
              </Text>
              {employees && employees.length ? (
                <VStack space="4">
                  <HStack space="2" alignItems="baseline">
                    <Text color="muted.600">{file.name}</Text>
                    <Button
                      size="sm"
                      colorScheme="secondary"
                      onPress={() => {
                        setFile(null);
                        setEmployees([]);
                      }}>
                      Re-upload
                    </Button>
                  </HStack>
                  <MaterialReactTable table={table} />
                </VStack>
              ) : (
                <Center
                  w="full"
                  h="150px"
                  ref={drop}
                  zIndex="10"
                  borderWidth="3"
                  borderColor={isDraggedOver ? 'indigo.400' : 'gray.300'}
                  borderRadius="xl"
                  borderStyle="dashed"
                  bg={isDraggedOver ? 'indigo.50' : 'transparent'}>
                  {fileError && <Text color="red.500">{fileError}</Text>}
                  <Text textAlign="center">
                    Drop the employees excel sheet file here
                    <br />
                    or
                  </Text>
                  <input
                    ref={fileInput}
                    type="file"
                    accept=".xlsx,.xls"
                    hidden
                  />
                  <Button
                    colorScheme="indigo"
                    variant="subtle"
                    onPress={() => {
                      fileInput.current.click();
                    }}>
                    Upload Excel
                  </Button>
                </Center>
              )}
            </VStack>

            <HStack justifyContent="space-between" alignItems="baseline">
              <Text color="muted.600">
                <FaCircleInfo /> You can add more employees later
              </Text>
              {employees.length > 0 && (
                <Button
                  colorScheme="indigo"
                  px="4"
                  onPress={() => {
                    // setAreDetailsInvalid(!areDetailsInvalid);
                    // phoneInputRef.current.focus();
                    setStep(5);
                  }}>
                  <Text color="white">
                    Proceed with
                    <Text bold> {employees.length}</Text> employees
                  </Text>
                </Button>
              )}
            </HStack>
          </VStack>
        )}

        {step === 5 && (
          <VStack
            space="8"
            px={['4', '16']}
            w="full"
            flex="1"
            justifyContent="center">
            <Heading textAlign={['center', 'left']}>Financials</Heading>
            <VStack space="4">
              <Text>Set the rates for travel allowance per kilometer</Text>

              <FormControl>
                <VStack space="4">
                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>2-wheeler</FormControl.Label>
                    <Input value="3" />
                  </HStack>

                  <HStack space="4" alignItems="baseline">
                    <FormControl.Label>4-wheeler</FormControl.Label>
                    <Input value="6" />
                  </HStack>
                </VStack>
              </FormControl>
            </VStack>

            <Button
              colorScheme="indigo"
              onPress={() => {
                // setAreDetailsInvalid(!areDetailsInvalid);
                // phoneInputRef.current.focus();
                setStep(5);
              }}>
              Launch
            </Button>
          </VStack>
        )}
      </VStack>
    </View>
  );
}
