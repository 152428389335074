import {createContext, useContext} from 'react';
import {auth as firebaseAuth} from './firebase.js';
import User from './models/User';
import {useUser} from './contexts/userContext';

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
  const [user, setUser] = useUser();
  let confirmResponse = null;
  const signUp = async (phoneNumber, appVerifier) => {
    const response = await firebaseAuth.signInWithPhoneNumber(phoneNumber, appVerifier);
    confirmResponse = response;
  };

  const verifyOtp = (otp) => {
    return confirmResponse.confirm(otp);
  }

  const login = async (email, password) => {
    const userCredential = await firebaseAuth.signInWithEmailAndPassword(
      email,
      password,
    );

    const u = new User({
      email: userCredential.user.email,
      department: userCredential.user.email.split('@')[0],
      organizationId: userCredential.user.email.includes('suryakirangroup')
        ? '1106'
        : '1604',
      });
    setUser(u);
    return u;
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{user, login, logout, signUp, verifyOtp}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
