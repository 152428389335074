import {useState} from 'react';
import {
  Center,
  Container,
  Heading,
  Stack,
  Image,
  Input,
  FormControl,
  Button,
  Pressable,
  Text,
  VStack,
} from 'native-base';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../auth.js';
import {useUser} from '../contexts/userContext';
import {MdVisibility, MdVisibilityOff} from 'react-icons/md';

const Login = () => {
  const [email, setEmail] = useState('');
  const [user, setUser] = useUser();
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const auth = useAuth();

  const signIn = async (email, password) => {
    try {
      await auth.login(email, password);
    } catch (error) {
      console.error(error.message);
      throw {error: error.message};
    }
    return;
  };

  const handleSubmit = async () => {
    try {
      await signIn(email, password);
      navigate('/trips');
    } catch (e) {
      setError(e);
    }
  };

  const onKeyDown = event => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };
  return (
    <Stack
      direction={{base: 'col', md: 'col', lg: 'row'}}
      width="full"
      height="100vh"
      bg="black"
      justifyContent="center"
      space="8"
      alignItems="center">
      <VStack flex={{base: 'none', md: 'none', lg: 1}} w="full" space="4">
        <Image
          size="sm"
          w="full"
          h={{base: '32px', md: '32px', lg: '64px'}}
          resizeMode="contain"
          src="tourexpe_logo.png"
          alt="tourexpe logo"
        />
        <Image
          size="md"
          w="full"
          h={{base: '48px', md: '48px', lg: '84px'}}
          resizeMode="contain"
          src="tourexpe_console_logo.png"
          alt="tourexpe console logo"
        />
      </VStack>
      <Container
        flex={{base: 'none', md: 'none', lg: 1}}
        alignItems="center"
        justifyContent="center">
        <FormControl isInvalid={error} alignItems="center">
          <VStack
            borderRadius="lg"
            bg="coolGray.800"
            p="8"
            py="12"
            width="full"
            maxWidth="400px"
            alignItems="center"
            space="8">
            <Heading color="white">Log in</Heading>
            <VStack space="4" w="full" alignItems="center">
              <Input
                color="white"
                width="100%"
                size="lg"
                value={email}
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
              />
              <Input
                color="white"
                width="100%"
                size="lg"
                value={password}
                placeholder="Password"
                onKeyPress={onKeyDown}
                onChange={e => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                InputRightElement={
                  <Pressable
                    px="2"
                    onPress={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <MdVisibility color="gray" size="20" />
                    ) : (
                      <MdVisibilityOff color="gray" size="20" />
                    )}
                  </Pressable>
                }
              />
              <FormControl.ErrorMessage>
                Wrong email or password
              </FormControl.ErrorMessage>
            </VStack>
            <Button
              w="full"
              size="lg"
              onPress={handleSubmit}
              colorScheme="indigo">
              <Text fontSize="lg" fontWeight="600" color="white">
                Log in
              </Text>
            </Button>
          </VStack>
        </FormControl>
      </Container>
    </Stack>
  );
};

export default Login;
